import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import * as CANNON from "cannon-es";
import TWEEN from "@tweenjs/tween.js";
import { processGLTF } from "./utils.js";

class Pigeon {
	constructor(scene, world, physicsMaterial, meshes, bodies, camera, listener) {
		this.scene = scene;
		this.world = world;
		this.physicsMaterial = physicsMaterial;
		this.meshes = meshes;
		this.bodies = bodies;
		this.camera = camera;
		this.listener = listener;

		this.model = null;
		this.sound = null;
		this.isCameraClose = false;

		this.loadModel();
		this.setupEventListeners();
	}

	loadModel() {
		const dracoLoader = new DRACOLoader().setDecoderPath(
			"/path/to/draco/gltf/"
		);
		const loader = new GLTFLoader().setDRACOLoader(dracoLoader);

		loader.load("/assets/gltf/pigeon.glb", (gltf) => {
			processGLTF(gltf.scene);
			this.model = gltf.scene;
			this.setupPigeon();
		});
	}

	setupPigeon() {
		this.model.scale.set(2, 2, 2);
		this.model.position.set(0, -0.6, 0);
		this.scene.add(this.model);

		const pigeonBody = new CANNON.Body({
			mass: 1,
			position: new CANNON.Vec3(...this.model.position.toArray()),
			shape: this.createPigeonShape(),
			material: this.physicsMaterial,
		});
		this.world.addBody(pigeonBody);
		this.bodies.push(pigeonBody);
		this.meshes.push(this.model);

		this.setupSound();
	}

	setupSound() {
		this.sound = new THREE.PositionalAudio(this.listener);
		const audioLoader = new THREE.AudioLoader();
		audioLoader.load("/assets/sounds/pigeon.m4a", (buffer) => {
			this.sound.setBuffer(buffer);
			this.sound.setRefDistance(10);
			this.sound.setLoop(true);
			this.sound.setVolume(0.1);
		});
		this.model.add(this.sound);
	}

	setupEventListeners() {
		window.addEventListener("click", this.onMouseClick.bind(this), false);
	}

	onMouseClick(event) {
		const raycaster = new THREE.Raycaster();
		const mouse = new THREE.Vector2(
			(event.clientX / window.innerWidth) * 2 - 1,
			-(event.clientY / window.innerHeight) * 2 + 1
		);

		raycaster.setFromCamera(mouse, this.camera);
		const intersects = raycaster.intersectObject(this.model, true);

		if (intersects.length > 0) {
			this.toggleSound();
			this.toggleCameraPosition();
		}
	}

	toggleSound() {
		if (this.sound.isPlaying) {
			this.sound.pause();
		} else {
			this.sound.play();
		}
	}

	toggleCameraPosition() {
		const targetPosition = this.isCameraClose
			? new THREE.Vector3(0.1, 5, 30)
			: this.model.position.clone().add(new THREE.Vector3(0, 2, 5));

		const lookAtPosition = this.isCameraClose
			? new THREE.Vector3(0, 0, 0)
			: this.model.position;

		this.moveCameraToPosition(targetPosition, lookAtPosition);
		this.isCameraClose = !this.isCameraClose;
	}

	moveCameraToPosition(position, lookAtPosition) {
		new TWEEN.Tween(this.camera.position)
			.to(position, 2000)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.onUpdate(() => this.camera.lookAt(lookAtPosition))
			.start();
	}

	createPigeonShape() {
		const size = new THREE.Box3()
			.setFromObject(this.model)
			.getSize(new THREE.Vector3())
			.multiplyScalar(0.5);
		return new CANNON.Box(new CANNON.Vec3(size.x, size.y, size.z));
	}

	getModel() {
		return this.model;
	}
}

export function createPigeon(
	scene,
	world,
	physicsMaterial,
	meshes,
	bodies,
	camera,
	listener
) {
	return new Pigeon(
		scene,
		world,
		physicsMaterial,
		meshes,
		bodies,
		camera,
		listener
	);
}
