import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import * as CANNON from "cannon-es";
import TWEEN from "@tweenjs/tween.js";
import { updateDotPosition, processGLTF } from "./utils.js";

class Bridge {
	constructor(scene, world, physicsMaterial, meshes, bodies, camera) {
		this.scene = scene;
		this.world = world;
		this.physicsMaterial = physicsMaterial;
		this.meshes = meshes;
		this.bodies = bodies;
		this.camera = camera;

		this.model = null;
		this.body = null;
		this.isCameraClose = false;

		this.loadModel();
	}

	loadModel() {
		const dracoLoader = new DRACOLoader();
		dracoLoader.setDecoderPath("/path/to/draco/gltf/");

		const loader = new GLTFLoader();
		loader.setDRACOLoader(dracoLoader);

		const textureLoader = new THREE.TextureLoader();
		const texture = textureLoader.load("/assets/files/text.jpg", (texture) => {
			texture.wrapS = THREE.RepeatWrapping;
			texture.wrapT = THREE.RepeatWrapping;
			texture.repeat.set(2, 2);
		});

		loader.load(
			"/assets/gltf/bridge.glb",
			(gltf) => {
				this.prepareBridgeModel(gltf, texture);
				this.setupBridge();
				this.addClickListener();
			},
			undefined,
			(error) => {
				console.error(
					"An error happened while loading the bridge model.",
					error
				);
			}
		);
	}

	prepareBridgeModel(gltf, texture) {
		gltf.scene.traverse((child) => {
			if (child.isMesh) {
				child.castShadow = true;
				child.receiveShadow = true;
				child.material.map = texture;
				child.material.metalness = 0.4;
				child.material.roughness = 0.1;
			}
		});
		this.model = gltf.scene;
		processGLTF(this.model);
	}

	setupBridge() {
		this.model.scale.set(4, 4, 4);
		this.model.position.set(4, -0.3, -4);
		this.model.rotation.y = Math.PI / 3;
		this.scene.add(this.model);

		const shape = this.createCannonShape();
		this.body = new CANNON.Body({
			mass: 0,
			position: new CANNON.Vec3(...this.model.position.toArray()),
			shape: shape,
			material: this.physicsMaterial,
		});

		this.world.addBody(this.body);
		this.meshes.push(this.model);
		this.bodies.push(this.body);

		this.body.position.copy(this.model.position);
		this.body.quaternion.copy(this.model.quaternion);

		this.updateDotPosition();
	}

	createCannonShape() {
		const box = new THREE.Box3().setFromObject(this.model);
		const size = box.getSize(new THREE.Vector3()).multiplyScalar(0.5);
		return new CANNON.Box(new CANNON.Vec3(size.x, size.y, size.z));
	}

	addClickListener() {
		window.addEventListener("click", this.onBridgeClick.bind(this), false);
	}

	onBridgeClick(event) {
		const raycaster = new THREE.Raycaster();
		const mouse = new THREE.Vector2(
			(event.clientX / window.innerWidth) * 2 - 1,
			-(event.clientY / window.innerHeight) * 2 + 1
		);

		raycaster.setFromCamera(mouse, this.camera);
		const intersects = raycaster.intersectObject(this.model, true);

		if (intersects.length > 0) {
			this.toggleCameraPosition();
		}
	}

	toggleCameraPosition() {
		const targetPosition = this.isCameraClose
			? new THREE.Vector3(0.1, 5, 30)
			: this.model.position.clone().add(new THREE.Vector3(10, 5, -10));

		const lookAtPosition = this.isCameraClose
			? new THREE.Vector3(0, 0, 0)
			: this.model.position;

		this.moveCameraToPosition(targetPosition, lookAtPosition);
		this.isCameraClose = !this.isCameraClose;
	}

	moveCameraToPosition(position, lookAtPosition) {
		new TWEEN.Tween(this.camera.position)
			.to(position, 2000)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.onUpdate(() => this.camera.lookAt(lookAtPosition))
			.start();
	}

	updateDotPosition() {
		updateDotPosition(
			this.model,
			document.querySelector(".dot-bridge"),
			0,
			-60,
			this.camera
		);
	}

	getModel() {
		return this.model;
	}

	getBody() {
		return this.body;
	}
}

let bridgeInstance = null;

export function loadBridge(
	scene,
	world,
	physicsMaterial,
	meshes,
	bodies,
	camera
) {
	bridgeInstance = new Bridge(
		scene,
		world,
		physicsMaterial,
		meshes,
		bodies,
		camera
	);
	return bridgeInstance;
}

export function getBridge() {
	return bridgeInstance ? bridgeInstance.getModel() : null;
}

export function getBridgeBody() {
	return bridgeInstance ? bridgeInstance.getBody() : null;
}
