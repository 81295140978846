import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import * as CANNON from "cannon-es";
import TWEEN from "@tweenjs/tween.js";
import { updateDotPosition } from "./utils.js";

class Fruitbox {
	constructor(scene, world, physicsMaterial, meshes, bodies, camera, listener) {
		this.scene = scene;
		this.world = world;
		this.physicsMaterial = physicsMaterial;
		this.meshes = meshes;
		this.bodies = bodies;
		this.camera = camera;
		this.listener = listener;

		this.model = null;
		this.sound = null;
		this.isCameraClose = false;

		this.loadModel();
		this.setupEventListeners();
	}

	loadModel() {
		const dracoLoader = new DRACOLoader().setDecoderPath(
			"/path/to/draco/gltf/"
		);
		const loader = new GLTFLoader().setDRACOLoader(dracoLoader);

		loader.load(
			"/assets/gltf/fruitbox.glb",
			(gltf) => {
				this.model = gltf.scene;
				this.setupFruitbox();
			},
			undefined,
			(error) => console.error("Error loading fruitbox model:", error)
		);
	}

	setupFruitbox() {
		this.model.scale.set(4.5, 4.5, 4.5);
		this.model.position.set(6.8, -1.28, -1.5);
		this.scene.add(this.model);

		this.model.traverse((child) => {
			if (child.isMesh) {
				child.rotation.z = Math.PI / 3;
				child.castShadow = true;
				child.receiveShadow = true;
				child.material.metalness = -0.9;
			}
		});

		const fruitboxBody = new CANNON.Body({
			mass: 0,
			position: new CANNON.Vec3().copy(this.model.position),
			shape: this.createFruitboxShape(),
			material: this.physicsMaterial,
		});
		this.world.addBody(fruitboxBody);
		this.bodies.push(fruitboxBody);
		this.meshes.push(this.model);

		this.updateDotPosition();
		this.setupSound();
	}

	createFruitboxShape() {
		const size = new THREE.Box3()
			.setFromObject(this.model)
			.getSize(new THREE.Vector3())
			.multiplyScalar(0.5);
		return new CANNON.Box(new CANNON.Vec3(size.x, size.y, size.z));
	}

	setupSound() {
		this.sound = new THREE.PositionalAudio(this.listener);
		const audioLoader = new THREE.AudioLoader();

		audioLoader.load(
			"/assets/sounds/fruitbox.wav",
			(buffer) => {
				this.sound.setBuffer(buffer);
				this.sound.setRefDistance(10);
				this.sound.setLoop(true);
				this.sound.setVolume(0.1);
				console.log("Fruitbox audio loaded successfully");
			},
			undefined,
			(error) => console.error("Error loading fruitbox audio:", error)
		);
		this.model.add(this.sound);
	}

	updateDotPosition() {
		const dotFruitbox = document.querySelector(".dot-fruitbox");
		if (dotFruitbox) {
			updateDotPosition(this.model, dotFruitbox, 60, 0, this.camera);
		}
	}

	setupEventListeners() {
		window.addEventListener("click", this.onMouseClick.bind(this));
	}

	onMouseClick(event) {
		const raycaster = new THREE.Raycaster();
		const mouse = new THREE.Vector2(
			(event.clientX / window.innerWidth) * 2 - 1,
			-(event.clientY / window.innerHeight) * 2 + 1
		);

		raycaster.setFromCamera(mouse, this.camera);
		const intersects = raycaster.intersectObject(this.model, true);

		if (intersects.length > 0) {
			this.toggleSound();
			this.toggleCameraPosition();
		}
	}

	toggleSound() {
		if (this.sound.isPlaying) {
			this.sound.pause();
		} else {
			this.sound.play();
		}
	}

	toggleCameraPosition() {
		const targetPosition = this.isCameraClose
			? new THREE.Vector3(0.1, 5, 30)
			: this.model.position.clone().add(new THREE.Vector3(10, 3, -4));

		const lookAtPosition = this.isCameraClose
			? new THREE.Vector3(0, 0, 0)
			: this.model.position;

		this.moveCameraToPosition(targetPosition, lookAtPosition);
		this.isCameraClose = !this.isCameraClose;
	}

	moveCameraToPosition(position, lookAtPosition) {
		new TWEEN.Tween(this.camera.position)
			.to(position, 2000)
			.easing(TWEEN.Easing.Quadratic.InOut)
			.onUpdate(() => this.camera.lookAt(lookAtPosition))
			.start();
	}

	getModel() {
		return this.model;
	}
}

let fruitboxInstance = null;

export function loadFruitbox(
	scene,
	world,
	physicsMaterial,
	meshes,
	bodies,
	camera,
	listener
) {
	fruitboxInstance = new Fruitbox(
		scene,
		world,
		physicsMaterial,
		meshes,
		bodies,
		camera,
		listener
	);
	return fruitboxInstance;
}

export function getFruitbox() {
	return fruitboxInstance ? fruitboxInstance.getModel() : null;
}
