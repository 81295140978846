import * as THREE from "three";
import * as CANNON from "cannon-es";
import { createCannonShape } from "./utils.js";

class BoxGeometries {
	constructor(scene, world, physicsMaterial, meshes, bodies) {
		this.scene = scene;
		this.world = world;
		this.physicsMaterial = physicsMaterial;
		this.meshes = meshes;
		this.bodies = bodies;

		this.geometries = [
			new THREE.BoxGeometry(2, 1, 1),
			new THREE.BoxGeometry(2, 1, 1),
			new THREE.BoxGeometry(2, 1, 1),
			new THREE.CylinderGeometry(0.5, 0.5, 2, 20),
			new THREE.BoxGeometry(1, 1, 1),
		];

		this.materials = [
			this.createMaterial(0xff0000),
			this.createMaterial(0x00ff00),
			this.createMaterial(0x0000ff),
			this.createMaterial(0xffff00),
			this.createMaterial(0xf522d5),
		];

		this.floorSize = 8;
	}

	load() {
		this.geometries.forEach((geometry, index) => {
			const position = this.getRandomPosition();
			const material = this.materials[index % this.materials.length];
			const mesh = this.createMesh(geometry, material, position);
			const body = this.createBody(geometry, position);

			this.scene.add(mesh);
			this.meshes.push(mesh);
			this.world.addBody(body);
			this.bodies.push(body);
		});
	}

	getRandomPosition() {
		const x = (Math.random() - 0.5) * this.floorSize;
		const z = (Math.random() - 0.5) * this.floorSize;
		const y = this.getGeometryHeight(this.geometries[0]) / 2 - 1;
		return { x, y, z };
	}

	createMesh(geometry, material, position) {
		const mesh = new THREE.Mesh(geometry, material);
		mesh.position.set(position.x, position.y, position.z);
		mesh.castShadow = true;
		mesh.receiveShadow = true;
		return mesh;
	}

	createBody(geometry, position) {
		const shape = createCannonShape(geometry);
		return new CANNON.Body({
			mass: 0.15,
			position: new CANNON.Vec3(position.x, position.y, position.z),
			shape: shape,
			material: this.physicsMaterial,
		});
	}

	createMaterial(color) {
		return new THREE.MeshStandardMaterial({
			color: color,
			metalness: 0.4,
			roughness: 0.1,
		});
	}

	getGeometryHeight(geometry) {
		const heightMap = {
			BoxGeometry: (g) => g.parameters.height,
			CylinderGeometry: (g) => g.parameters.height,
			ConeGeometry: (g) => g.parameters.height,
			SphereGeometry: (g) => g.parameters.radius * 2,
			IcosahedronGeometry: (g) => g.parameters.radius * 2,
			OctahedronGeometry: (g) => g.parameters.radius * 2,
			TetrahedronGeometry: (g) => g.parameters.radius * 2,
		};

		return (heightMap[geometry.type] || (() => 1))(geometry);
	}
}

export function loadBoxGeometries(
	scene,
	world,
	physicsMaterial,
	meshes,
	bodies
) {
	const boxGeometries = new BoxGeometries(
		scene,
		world,
		physicsMaterial,
		meshes,
		bodies
	);
	boxGeometries.load();
}
