import * as THREE from "three";
import * as CANNON from "cannon-es";

// Use THREE.sRGBEncoding directly
const { sRGBEncoding } = THREE;

/**
 * Processes a GLTF scene by setting the correct texture encoding and other material properties.
 * @param {THREE.Object3D} scene - The loaded GLTF scene.
 */
export function processGLTF(gltf) {
	gltf.traverse((child) => {
		if (child.isMesh && child.material && child.material.map) {
			child.material.map.encoding = sRGBEncoding;
			child.material.needsUpdate = true;
		}
	});
}

/**
 * Creates a bounding box shape for the given 3D object.
 * @param {THREE.Object3D} object - The 3D object to create the bounding box shape for.
 * @returns {CANNON.Box} - The bounding box shape.
 */
export function createBoundingBoxShape(object) {
	const box = new THREE.Box3().setFromObject(object);
	const size = box.getSize(new THREE.Vector3()).multiplyScalar(0.5);
	return new CANNON.Box(new CANNON.Vec3(size.x, size.y, size.z));
}

/**
 * Creates a corresponding Cannon.js shape for the given Three.js geometry.
 * @param {THREE.Geometry} geometry - The Three.js geometry.
 * @returns {CANNON.Shape} - The Cannon.js shape.
 */
export function createCannonShape(geometry) {
	switch (geometry.type) {
		case "BoxGeometry":
			return new CANNON.Box(
				new CANNON.Vec3(
					geometry.parameters.width / 2,
					geometry.parameters.height / 2,
					geometry.parameters.depth / 2
				)
			);
		case "IcosahedronGeometry":
		case "OctahedronGeometry":
		case "TetrahedronGeometry":
			return new CANNON.Sphere(geometry.parameters.radius);
		case "ConeGeometry":
		case "CylinderGeometry":
			return new CANNON.Cylinder(
				geometry.parameters.radiusTop || geometry.parameters.radius,
				geometry.parameters.radiusBottom || geometry.parameters.radius,
				geometry.parameters.height,
				geometry.parameters.radialSegments || 32
			);
		case "SphereGeometry":
			return new CANNON.Sphere(geometry.parameters.radius);
		default:
			console.warn("Unknown geometry type, defaulting to box shape.");
			return new CANNON.Box(new CANNON.Vec3(0.1, 0.1, 0.1));
	}
}

/**
 * Updates the position of a dot element on the screen to follow a 3D object.
 * @param {THREE.Object3D} object - The 3D object to follow.
 * @param {HTMLElement} dotElement - The dot element to update.
 * @param {number} offsetX - Horizontal offset.
 * @param {number} offsetY - Vertical offset.
 * @param {THREE.Camera} camera - The camera used for the scene.
 */
export function updateDotPosition(
	object,
	dotElement,
	offsetX,
	offsetY,
	camera
) {
	const vector = new THREE.Vector3();
	object.updateMatrixWorld();
	vector.setFromMatrixPosition(object.matrixWorld);
	vector.project(camera);

	const x = (vector.x * 0.5 + 0.5) * window.innerWidth + offsetX;
	const y = (vector.y * -0.5 + 0.5) * window.innerHeight + offsetY;

	dotElement.style.transform = `translate(${x}px, ${y}px)`;
}

/**
 * Debounces a function to limit the rate at which it can fire.
 * @param {Function} func - The function to debounce.
 * @param {number} wait - The number of milliseconds to wait before invoking the function.
 * @returns {Function} - The debounced function.
 */
export function debounce(func, wait) {
	let timeout;
	return function (...args) {
		const context = this;
		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(context, args), wait);
	};
}

/**
 * Creates a standard material with optional shadow settings.
 * @param {number} color - The color of the material.
 * @param {boolean} [castShadow=false] - Whether the material casts a shadow.
 * @param {boolean} [receiveShadow=false] - Whether the material receives a shadow.
 * @returns {THREE.MeshStandardMaterial} - The created material.
 */
export function createStandardMaterial(
	color,
	castShadow = false,
	receiveShadow = false
) {
	const material = new THREE.MeshStandardMaterial({ color });
	material.castShadow = castShadow;
	material.receiveShadow = receiveShadow;
	return material;
}
