import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import * as CANNON from "cannon-es";
import { processGLTF, createBoundingBoxShape } from "./utils.js";
import { getFruitbox } from "./fruitbox.js"; // Import this function

class Vegetables {
	constructor(scene, world, physicsMaterial, meshes, bodies) {
		this.scene = scene;
		this.world = world;
		this.physicsMaterial = physicsMaterial;
		this.meshes = meshes;
		this.bodies = bodies;

		this.vegetableModels = [];
		this.floorSize = 8;
	}

	async load() {
		const loader = this.setupLoader();
		const vegetableFiles = ["carrot", "cabbage"];

		try {
			const loadedModels = await Promise.all(
				vegetableFiles.map((file) => this.loadModel(loader, file))
			);
			this.vegetableModels = loadedModels;
			this.placeVegetables();
		} catch (error) {
			console.error("Error loading vegetable models:", error);
		}
	}

	setupLoader() {
		const dracoLoader = new DRACOLoader();
		dracoLoader.setDecoderPath("/path/to/draco/gltf/");
		const loader = new GLTFLoader();
		loader.setDRACOLoader(dracoLoader);
		return loader;
	}

	loadModel(loader, file) {
		return new Promise((resolve, reject) => {
			loader.load(
				`/assets/gltf/${file}.glb`,
				(gltf) => {
					processGLTF(gltf.scene);
					resolve(gltf.scene);
				},
				undefined,
				reject
			);
		});
	}

	placeVegetables() {
		const fruitboxPosition = this.getFruitboxPosition();

		this.vegetableModels.forEach((model, index) => {
			let position;
			if (index === 0) {
				// Carrot
				position = {
					x: fruitboxPosition.x + 6,
					y: 0,
					z: fruitboxPosition.z,
				};
			} else if (index === 1) {
				// Cabbage
				position = {
					x: fruitboxPosition.x + 6,
					y: 0,
					z: fruitboxPosition.z + 0.5,
				};
			} else {
				position = this.getRandomPosition();
			}
			this.setupVegetable(model, position);
		});
	}

	getFruitboxPosition() {
		const fruitbox = getFruitbox();
		return fruitbox ? fruitbox.position : new THREE.Vector3(0, 0, 0);
	}

	getRandomPosition() {
		return {
			x: (Math.random() - 0.5) * this.floorSize,
			y: 0,
			z: (Math.random() - 0.5) * this.floorSize,
		};
	}

	setupVegetable(model, position) {
		model.scale.set(0.5, 0.5, 0.5);
		model.position.set(position.x, position.y, position.z);
		this.scene.add(model);
		this.meshes.push(model);

		const shape = createBoundingBoxShape(model);
		const body = new CANNON.Body({
			mass: 0.15,
			position: new CANNON.Vec3(position.x, position.y, position.z),
			shape: shape,
			material: this.physicsMaterial,
		});

		this.world.addBody(body);
		this.bodies.push(body);
	}
}

let vegetablesInstance = null;

export function loadVegetables(scene, world, physicsMaterial, meshes, bodies) {
	vegetablesInstance = new Vegetables(
		scene,
		world,
		physicsMaterial,
		meshes,
		bodies
	);
	return vegetablesInstance.load();
}
