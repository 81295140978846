import * as THREE from "three";
import * as CANNON from "cannon-es";
import { createCannonShape } from "./utils.js";

class Utopia {
	constructor(scene, world, physicsMaterial, meshes, bodies) {
		this.scene = scene;
		this.world = world;
		this.physicsMaterial = physicsMaterial;
		this.meshes = meshes;
		this.bodies = bodies;

		this.textureLoader = new THREE.TextureLoader();
		this.textures = {
			seraina: this.textureLoader.load("/assets/files/seraina.jpg"),
			space: this.textureLoader.load("/assets/files/space.jpg"),
			moon: this.textureLoader.load("/assets/files/moon.jpg"),
		};

		this.materials = this.createMaterials();
		this.geometries = this.createGeometries();
		this.floorSize = 8;
		this.secondUtopiaGeometry = null;
	}

	createMaterials() {
		return Object.values(this.textures).map(
			(texture) =>
				new THREE.MeshStandardMaterial({
					map: texture,
					metalness: 0.3,
					roughness: 0.1,
				})
		);
	}

	createGeometries() {
		return [
			new THREE.BoxGeometry(1, 2.5, 1),
			new THREE.BoxGeometry(1, 4, 2),
			new THREE.BoxGeometry(2, 2, 1),
		];
	}

	load() {
		this.geometries.forEach((geometry, index) => {
			const position = this.getRandomPosition();
			const material = this.materials[index % this.materials.length];
			const mesh = this.createMesh(geometry, material, position);

			if (index === 1) {
				this.secondUtopiaGeometry = mesh;
			}

			this.scene.add(mesh);
			this.meshes.push(mesh);

			const body = this.createBody(geometry, position);
			this.world.addBody(body);
			this.bodies.push(body);
		});
	}

	getRandomPosition() {
		return {
			x: (Math.random() - 0.5) * this.floorSize,
			y: this.getGeometryHeight(this.geometries[0]) / 2 - 1,
			z: (Math.random() - 0.5) * this.floorSize,
		};
	}

	createMesh(geometry, material, position) {
		const mesh = new THREE.Mesh(geometry, material);
		mesh.position.set(position.x, position.y, position.z);
		mesh.castShadow = true;
		mesh.receiveShadow = true;
		return mesh;
	}

	createBody(geometry, position) {
		const shape = createCannonShape(geometry);
		return new CANNON.Body({
			mass: 0.15,
			position: new CANNON.Vec3(position.x, position.y, position.z),
			shape: shape,
			material: this.physicsMaterial,
		});
	}

	getGeometryHeight(geometry) {
		const heightMap = {
			BoxGeometry: (g) => g.parameters.height,
			CylinderGeometry: (g) => g.parameters.height,
			ConeGeometry: (g) => g.parameters.height,
			SphereGeometry: (g) => g.parameters.radius * 2,
			IcosahedronGeometry: (g) => g.parameters.radius * 2,
			OctahedronGeometry: (g) => g.parameters.radius * 2,
			TetrahedronGeometry: (g) => g.parameters.radius * 2,
		};

		return (heightMap[geometry.type] || (() => 1))(geometry);
	}

	getSecondUtopiaGeometry() {
		return this.secondUtopiaGeometry;
	}
}

let utopiaInstance = null;

export function loadUtopiaGeometries(
	scene,
	world,
	physicsMaterial,
	meshes,
	bodies
) {
	utopiaInstance = new Utopia(scene, world, physicsMaterial, meshes, bodies);
	utopiaInstance.load();
}

export function getSecondUtopiaGeometry() {
	return utopiaInstance ? utopiaInstance.getSecondUtopiaGeometry() : null;
}
