import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass";
import TWEEN from "@tweenjs/tween.js";
import * as CANNON from "cannon-es";

import { loadBoxGeometries } from "./boxGeometries.js";
import { loadUtopiaGeometries, getSecondUtopiaGeometry } from "./utopia.js";
import { createPigeon } from "./pigeon.js";
import { loadWoodlog, getWoodlog } from "./woodlog.js";
import { loadBridge, getBridge } from "./bridge.js";
import { loadFruitbox, getFruitbox } from "./fruitbox.js";
import { loadVegetables } from "./vegetables.js";
import { updateDotPosition, processGLTF } from "./utils.js";

class Scene {
	constructor() {
		this.camera = null;
		this.scene = null;
		this.renderer = null;
		this.composer = null;
		this.controls = null;
		this.listener = null;
		this.dragControls = null;
		this.clock = null;
		this.world = new CANNON.World({ gravity: new CANNON.Vec3(0, -9.82, 0) });
		this.meshes = [];
		this.bodies = [];
		this.draggableMeshes = [];
		this.audioContext = new (window.AudioContext ||
			window.webkitAudioContext)();
		this.isSoundOn = true;
		this.pigeon = null;
		this.woodlog = null;
		this.fruitbox = null;
		this.bridge = null;

		this.init();
	}

	init() {
		this.setupPhysics();
		this.setupCamera();
		this.setupScene();
		this.setupRenderer();
		this.setupLights();
		this.setupGround();
		this.loadGeometries();
		this.setupControls();
		this.setupPostProcessing();
		this.setupDragControls();

		this.clock = new THREE.Clock();

		window.addEventListener(
			"resize",
			this.debounce(this.onWindowResize.bind(this), 200)
		);
		window.addEventListener("click", this.onUserInteraction.bind(this));
		window.addEventListener("touchstart", this.onUserInteraction.bind(this));

		document
			.getElementById("sound-toggle")
			.addEventListener("click", this.handleSoundToggle.bind(this));

		this.animate();
	}

	setupPhysics() {
		const physicsMaterial = new CANNON.Material("physicsMaterial");
		const contactMaterial = new CANNON.ContactMaterial(
			physicsMaterial,
			physicsMaterial,
			{
				friction: 0.005,
				restitution: 0.005,
			}
		);
		this.world.addContactMaterial(contactMaterial);
	}

	setupCamera() {
		this.camera = new THREE.PerspectiveCamera(
			30,
			window.innerWidth / window.innerHeight,
			0.1,
			100
		);
		this.camera.position.set(0.1, 5, 30);
		this.listener = new THREE.AudioListener();
		this.camera.add(this.listener);
	}

	setupScene() {
		this.scene = new THREE.Scene();
		this.scene.background = new THREE.Color(0xefefef);
	}

	setupRenderer() {
		this.renderer = new THREE.WebGLRenderer({ antialias: true });
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize(window.innerWidth, window.innerHeight);
		this.renderer.shadowMap.enabled = true;
		this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
		this.renderer.outputEncoding = THREE.sRGBEncoding;
		this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
		this.renderer.toneMappingExposure = 1.5;
		document.body.appendChild(this.renderer.domElement);
	}

	setupLights() {
		const directionalLight = new THREE.DirectionalLight(0xffffff, 2.5);
		directionalLight.position.set(5, 8, 5);
		directionalLight.castShadow = true;
		directionalLight.shadow.mapSize.set(2048, 2048);
		directionalLight.shadow.bias = -0.0001;
		this.scene.add(directionalLight);

		const ambientLight = new THREE.AmbientLight(0xffffff, 3);
		this.scene.add(ambientLight);

		const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
		const envMap = pmremGenerator.fromScene(new THREE.Scene()).texture;
		this.scene.environment = envMap;
	}

	setupGround() {
		const plane = new THREE.Mesh(
			new THREE.PlaneGeometry(55, 55),
			new THREE.MeshStandardMaterial({ color: 0xefefef })
		);
		plane.rotation.x = -Math.PI / 2;
		plane.position.y = -1;
		plane.receiveShadow = true;
		this.scene.add(plane);

		const groundBody = new CANNON.Body({
			mass: 0,
			shape: new CANNON.Plane(),
			material: new CANNON.Material("physicsMaterial"),
		});
		groundBody.quaternion.setFromEuler(-Math.PI / 2, 0, 0);
		groundBody.position.copy(plane.position);
		this.world.addBody(groundBody);
	}

	loadGeometries() {
		const physicsMaterial = new CANNON.Material("physicsMaterial");

		const loaders = [
			loadBoxGeometries,
			loadUtopiaGeometries,
			loadFruitbox,
			loadVegetables,
		];

		loaders.forEach((loader) =>
			loader(
				this.scene,
				this.world,
				physicsMaterial,
				this.meshes,
				this.bodies,
				this.camera,
				this.listener
			)
		);

		this.pigeon = createPigeon(
			this.scene,
			this.world,
			physicsMaterial,
			this.meshes,
			this.bodies,
			this.camera,
			this.listener
		);
		this.woodlog = loadWoodlog(
			this.scene,
			this.world,
			physicsMaterial,
			this.meshes,
			this.bodies,
			this.camera,
			this.listener
		);
		this.bridge = loadBridge(
			this.scene,
			this.world,
			physicsMaterial,
			this.meshes,
			this.bodies,
			this.camera
		);

		this.meshes.forEach(processGLTF);

		this.draggableMeshes.push(
			...this.meshes.filter(
				(mesh) => ![getWoodlog(), getBridge(), getFruitbox()].includes(mesh)
			)
		);
	}

	setupControls() {
		this.controls = new OrbitControls(this.camera, this.renderer.domElement);
		this.controls.enableDamping = true;
		this.controls.dampingFactor = 0.25;
		this.controls.screenSpacePanning = false;
		this.controls.minDistance = 5;
		this.controls.maxDistance = 100;
		this.controls.maxPolarAngle = Math.PI / 2;
	}

	setupPostProcessing() {
		this.composer = new EffectComposer(this.renderer);
		this.composer.addPass(new RenderPass(this.scene, this.camera));
		this.composer.addPass(new FilmPass(0.35, 0.75, 2048, false));
	}

	setupDragControls() {
		this.dragControls = new DragControls(
			this.draggableMeshes,
			this.camera,
			this.renderer.domElement
		);
		this.dragControls.addEventListener(
			"dragstart",
			() => (this.controls.enabled = false)
		);
		this.dragControls.addEventListener(
			"dragend",
			() => (this.controls.enabled = true)
		);
		this.dragControls.addEventListener("drag", this.onDrag.bind(this));
	}

	onDrag({ object: draggedObject }) {
		const index = this.meshes.indexOf(draggedObject);
		if (index !== -1) {
			const body = this.bodies[index];
			body.position.copy(draggedObject.position);
			body.velocity.set(0, 0, 0);
			body.angularVelocity.set(0, 0, 0);
		}
	}

	onWindowResize() {
		this.camera.aspect = window.innerWidth / window.innerHeight;
		this.camera.updateProjectionMatrix();
		this.renderer.setSize(window.innerWidth, window.innerHeight);
		this.composer.setSize(window.innerWidth, window.innerHeight);
	}

	animate() {
		requestAnimationFrame(this.animate.bind(this));

		const deltaTime = this.clock.getDelta();
		this.world.step(1 / 60, deltaTime, 3);

		this.bodies.forEach((body, index) => {
			const mesh = this.meshes[index];
			mesh.position.copy(body.position);
			mesh.quaternion.copy(body.quaternion);
		});

		this.controls.update();
		this.composer.render();
		TWEEN.update();
		this.updateMenuDotPosition();
	}

	updateMenuDotPosition() {
		const updates = [
			{
				getObject: () => this.pigeon.getModel(),
				dotClass: ".dot-pigeon",
				offsetX: 0,
				offsetY: -30,
			},
			{
				getObject: getSecondUtopiaGeometry,
				dotClass: ".dot-utopia",
				offsetX: 30,
				offsetY: -30,
			},
			{
				getObject: () => this.woodlog.getModel(),
				dotClass: ".dot-kitchen",
				offsetX: 0,
				offsetY: 0,
			},
			{
				getObject: getBridge,
				dotClass: ".dot-bridge",
				offsetX: 0,
				offsetY: -80,
			},
			{
				getObject: getFruitbox,
				dotClass: ".dot-fruitbox",
				offsetX: 0,
				offsetY: -100,
			},
		];

		updates.forEach(({ getObject, dotClass, offsetX, offsetY }) => {
			const object = getObject();
			if (object) {
				updateDotPosition(
					object,
					document.querySelector(dotClass),
					offsetX,
					offsetY,
					this.camera
				);
			}
		});
	}

	onUserInteraction() {
		if (this.audioContext.state === "suspended") this.audioContext.resume();
	}

	debounce(func, wait) {
		let timeout;
		return function (...args) {
			clearTimeout(timeout);
			timeout = setTimeout(() => func.apply(this, args), wait);
		};
	}

	handleSoundToggle() {
		this.isSoundOn = !this.isSoundOn;

		const soundToggleBtn = document.getElementById("sound-toggle");
		const audioElements = document.querySelectorAll("audio, video");

		audioElements.forEach((audio) => {
			audio.muted = !this.isSoundOn;
		});

		if (this.listener) this.listener.gain.gain.value = this.isSoundOn ? 1 : 0;

		soundToggleBtn.dataset.toggled = this.isSoundOn;
		soundToggleBtn.querySelector(".off").style.display = this.isSoundOn
			? "none"
			: "block";
		soundToggleBtn.querySelector(".on").style.display = this.isSoundOn
			? "block"
			: "none";
	}
}

new Scene();
